import React from 'react'
import {Field, FieldInputProps, FieldMetaState, FieldProps, useForm} from 'react-final-form'
import {FormControlProps, FormItem, FormItemProps} from '@tencent/tea-component/lib/form'
import {DeepPartial, Merge} from 'ts-essentials'
import {Mutator} from "final-form";
import {ErrorTip} from '@tencent/tea-component/lib/tips';

/**
 * 表单这里目前存在三种对象:Field>FormItem>Input
 * 通常以Field为单位统一处理，也有部分场景使用多个Field组合处理（如国家码/手机号）
 * 自定义组件通常有两种：
 * 纯React组件 纯函数仅负责展示 prop类型通常为FormItemProps & InputProps
 * field组件 通常包装纯React组件
 */

/**
 * 将final-form的field state转换为 腾讯云组件的status
 * @param meta
 * @param validating
 */
export function getStatus<FieldValue>(meta: FieldMetaState<FieldValue>, validating?: boolean): FormControlProps['status'] | undefined {
    if (meta.active && validating) {
        return "validating";
    }
    if (!meta.active && meta.touched && meta.modified && meta.error) {
        return "error";
    }
    return;
}

export function getMessage<FieldValue>(meta: FieldMetaState<FieldValue>, validating?: boolean): string | undefined {
    return getStatus(meta, validating) === 'error' && meta.error
}

type Function<Arguments, Result> = (args: Arguments) => Result
type ValueOfFunction<Arguments, Result> = Result | Function<Arguments, Result>
export type DoublePartial<T> = {
    [P in keyof T]?: Partial<T[P]>
}

type FormItemPropsWithoutChildren = Omit<FormItemProps, "children">

/**
 * 纯React组件 通常使用的参数
 * 对表单的常见三种元素 配置对应的参数
 */
export type FinalFormItemProps<InputPropsType, HtmlType extends HTMLElement = HTMLElement, FieldValue = any> = {
    formItem: FormItemPropsWithoutChildren
    input: Merge<InputPropsType, FieldInputProps<FieldValue, HtmlType>>
}

export type FinalFormInputProps<InputPropsType, HtmlType extends HTMLElement = HTMLElement, FieldValue = any> =
    Omit<FinalFormItemProps<InputPropsType, HtmlType, FieldValue>, "formItem">

/**
 * 通用的FormItem包装组件 用来包装核心Input逻辑
 */
export function AbstractFinalFormItem<InputPropsType, HtmlType extends HTMLElement = HTMLElement, FieldValue = any>(
    InputComponent: React.ComponentType<FinalFormInputProps<InputPropsType, HtmlType, FieldValue>>):
    React.ComponentType<FinalFormItemProps<InputPropsType, HtmlType, FieldValue>> {
    return (props: FinalFormItemProps<InputPropsType, HtmlType, FieldValue>) =>
        <FormItem {...props.formItem}><InputComponent input={{...props.input}}/></FormItem>;
}

export function withFormItemConfig<InputPropsType, HtmlType extends HTMLElement = HTMLElement, FieldValue = any>(
    FormItemComponent: React.ComponentType<FinalFormItemProps<InputPropsType, HtmlType, FieldValue>>,
    options: ValueOfFunction<FinalFormItemProps<InputPropsType, HtmlType, FieldValue>, DeepPartial<FinalFormItemProps<InputPropsType, HtmlType, FieldValue>>>
): React.ComponentType<FinalFormItemProps<InputPropsType, HtmlType, FieldValue>> {
    return (props: FinalFormItemProps<InputPropsType, HtmlType, FieldValue>) => {
        let real_options: DeepPartial<FinalFormItemProps<InputPropsType, HtmlType, FieldValue>>;
        if (typeof options === 'function')
            real_options = options(props);
        else real_options = options;
        return <FormItemComponent
            // @ts-ignore
            formItem={{...real_options.formItem, ...props.formItem}} input={{...real_options.input, ...props.input}}/>
    }
}

/**
 * field组件通常使用的参数
 * 对其包装的元素 篇日志对应的参数
 */
export declare type FinalFieldProps<InputPropsType, HtmlType extends HTMLElement = HTMLElement, FieldValue = any> = {
    formItem?: FormItemPropsWithoutChildren
    field: FieldProps<FieldValue, HtmlType>
    input?: Partial<Merge<InputPropsType, FieldInputProps<FieldValue, HtmlType>>>
}

/**
 * 通用的FormItem Field包装组件 用来包装FormItem为Field
 */
export function AbstractFinalField<InputPropsType, HtmlType extends HTMLElement = HTMLElement, FieldValue = any>(FormItemComponent: React.ComponentType<FinalFormItemProps<InputPropsType, HtmlType, FieldValue>>) {
    return (props: FinalFieldProps<InputPropsType, HtmlType, FieldValue>) => <Field {...props.field}>{({input, meta}) =>
        <FormItemComponent
            formItem={{status: getStatus(meta), message: getMessage(meta), ...props.formItem}}
            input={{...input, ...props.input as Merge<InputPropsType, FieldInputProps<FieldValue, HtmlType>>}}/>}</Field>
}

export function withFieldConfig<InputPropsType, HtmlType extends HTMLElement = HTMLElement, FieldValue = any,
    Config extends FinalFieldProps<InputPropsType, HtmlType, FieldValue> = FinalFieldProps<InputPropsType, HtmlType, FieldValue>>(
    FieldComponent: React.ComponentType<FinalFieldProps<InputPropsType, HtmlType, FieldValue>>,
    options: ValueOfFunction<Config, DoublePartial<Config>>
): React.ComponentType<Config> {
    return (props: Config) => {
        let real_options: DoublePartial<Config>;
        if (typeof options === 'function')
            real_options = options(props);
        else real_options = options;
        return <FieldComponent
            formItem={{...real_options.formItem, ...props.formItem}} field={{...real_options.field, ...props.field}}
            input={{...real_options.input, ...props.input}}/>
    }
}

export const SetSubmitErrorMutator: Mutator<any> = (args, state) => {
    state.formState.submitError = args[0]
};

export const FinalFormSubmitError = () => {
    let form = useForm();
    let state = form.getState();
    let error = !state.dirtySinceLastSubmit && !state.submitting && state.submitError;
    error && console.log(form.getState());
    return error ? <ErrorTip errorText={error} retryText=''/> : null
};
