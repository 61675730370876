import React from 'react'
import {Form, FormTitle} from '@tencent/tea-component/lib/form'
import {NeedMoreFactor, NeedResetLoginError} from "../../backend/backend";
import {Form as FinalForm} from 'react-final-form'
import {FORM_ERROR} from 'final-form'
import {PasswordField} from "../common/password";
import {getNamespace, NamespaceField} from "../common/namespace";
import {FinalFormSubmitError, SetSubmitErrorMutator} from "../common/form";
import {LoginFooter} from "./common";
import {Route} from "../common/link";
import {globalParams, redirectUrl} from "../../index";
import {SpringBackend} from "../../backend/spring";
import {CountryCodeField} from "../common/countryCode";
import {PhoneField} from "../common/phone";
import {CodeField} from "../common/code";

export function PasswordSmsLoginForm(props: { backend: SpringBackend }) {
    return (
        <>
            <Route render={({history, location}) => (
                <FinalForm onSubmit={async (values: any) => {
                    try {
                        let result = await props.backend.smsLogin(values.nation, values.phone, values.code);
                        result = await props.backend.passwordLogin({
                            namespace: getNamespace(values),
                            nation: values.nation,
                            phone: values.phone
                        }, values.password);
                        if (result) return {[FORM_ERROR]: result};
                        if (location.state && location.state.oauth) {
                            result = await props.backend.thirdBind();
                            if (result) return {[FORM_ERROR]: result}
                        }
                        window.location.href = redirectUrl(globalParams)
                    } catch (e) {
                        if (e instanceof NeedResetLoginError) {
                            history.push(`/reset/password${window.location.search}`, {
                                reset: {
                                    type: 'login',
                                }, ...location.state
                            })
                        } else if (e instanceof NeedMoreFactor) {
                            let result = await props.backend.passwordLogin({
                                namespace: getNamespace(values),
                                nation: values.nation,
                                phone: values.phone
                            }, values.password);
                            if (result) return {[FORM_ERROR]: result};
                        } else {
                            console.log(e);
                            return {[FORM_ERROR]: '系统错误'}
                        }
                    }
                }} mutators={{SetSubmitErrorMutator}}>
                    {({handleSubmit, errors, values}) => (
                        <form onSubmit={handleSubmit}>
                            <FormTitle>双因子登录密码+验证码</FormTitle>
                            <Form>
                                <NamespaceField namespaceOn={values.namespace_on}/>
                                <CountryCodeField field={{name: 'nation'}}/>
                                <PhoneField field={{name: 'phone'}}/>
                                <CodeField field={{name: 'code'}} input={{
                                    buttonDisabled: errors.nation || errors.phone,
                                    onSend: () => props.backend.smsLoginSend(getNamespace(values), values.nation, values.phone)
                                }}/>
                                <PasswordField field={{name: 'password'}} passwordType={'login'}/>
                                <FinalFormSubmitError/>
                            </Form>
                            <LoginFooter/>
                        </form>
                    )}
                </FinalForm>
            )}/>
        </>
    )
}
