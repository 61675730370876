import {InputProps} from '@tencent/tea-component/lib/input'
import {AutoCompleteProps} from '@tencent/tea-component/lib/autocomplete'
import options from '../../email-suffix.json'
import {AbstractFinalField, withFieldConfig, withFormItemConfig} from "./form";
import {InputAutoCompleteFormItem} from "./inputFormItem";

const suffixOptions: AutoCompleteProps['options'] = options.map(e => ({value: e}));

export const Email = withFormItemConfig<InputProps>(InputAutoCompleteFormItem, props => {
    let input = props.input.value.split('@', 2);
    return {
        formItem: {label: '邮箱'},
        input: {
            placeholder: '邮箱',
            type: 'email',
            autoComplete: 'email',
            options: input.length < 2 ? [] :
                suffixOptions.filter(e => e.value.includes(input[1]))
                    .map(e => ({
                        //只展示后缀，补全使用完整地址
                        value: `${input[0]}@${e.value}`,
                        text: e.value
                    })),
            keyword: input[1]
        }
    }
});

export const validateEmail = (account: string) => /^[a-z0-9_.+-]+@[a-z0-9-]+\.[a-z0-9-.]+$/i.test(account) ? undefined : '请输入有效的邮箱地址';

export const EmailField = withFieldConfig(AbstractFinalField(Email), {
    field: {validate: validateEmail}
});
