import React from 'react'
import {Switch} from 'react-router-dom'
import {Link, Route} from "./link";
import {withPageContext2} from "./context";

const style = {marginRight: 5};
const Footer = withPageContext2(({context: {disable_email, disable_password, disable_reset, disable_sms, disable_reg, disable_password_sms_factors}}) =>
    <Route render={(props) => (
        <Switch>
            <Route path='/login/oauth2/callback'/>
            <Route>
                <Route path="/registration"
                       render={() => !props.location.state && <Link style={style} to='/login'>登录已有帐号</Link>}/>
                <Route path="/login" render={() => !props.location.state &&
                    !disable_reg && <Link style={style} to='/registration' className="registration">注册新帐号</Link>}/>
                {/*<Route path={[`/registration/password`, "/registration/email"]}*/}
                {/*       render={() => <Link style={style} to="/registration/sms">短信注册</Link>}/>*/}
                {/*<Route path={["/registration/password", "/registration/sms"]}*/}
                {/*       render={() => <Link style={style} to="/registration/email">邮箱注册</Link>}/>*/}
                <Route path='/registration'>
                    <Switch>
                        <Route path='/registration/password'/>
                        <Route><Link style={style} to="/registration/password" replace={true}>密码注册</Link></Route>
                    </Switch>
                </Route>
                <Route path="/login">
                    <Route
                        render={() => (!disable_sms || !disable_email) && !disable_reset && !props.location.state &&
                            <Link style={style} to="/reset/entry" className="forget">忘记密码</Link>}/>
                    <Switch>
                        <Route path='/login/sms'/>
                        <Route>
                            {!disable_sms &&
                            <Link style={style} to="/login/sms" replace={true} className="message">短信登录 </Link>}
                        </Route>
                    </Switch>
                    <Switch>
                        <Route path='/login/password'> </Route>
                        <Route>
                            {!disable_password &&
                            <Link style={style} to="/login/password" replace={true} className="message">密码登录 </Link>}
                        </Route>
                    </Switch>
                    <Switch>
                        <Route path='/login/password_sms'> </Route>
                        <Route>
                            {!disable_password_sms_factors &&
                            <Link style={style} to="/login/password_sms" replace={true} className="message">双因子登录 </Link>}
                        </Route>
                    </Switch>
                </Route>
                {/*<Route path={["/login/password", "/login/sms"]}*/}
                {/*       render={() => <Link style={style} to="/login/email">邮箱登录</Link>}/>*/}
            </Route>
        </Switch>
    )}/>
);
export {Footer}
