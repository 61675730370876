import React from 'react'
import {Form, FormTitle} from '@tencent/tea-component/lib/form'
import {AccountField, AccountType} from '../common/accountField'
import {NeedMoreFactor, NeedResetLoginError} from "../../backend/backend";
import {Form as FinalForm} from 'react-final-form'
import {FORM_ERROR} from 'final-form'
import {PasswordField} from "../common/password";
import {getNamespace, NamespaceField} from "../common/namespace";
import {FinalFormSubmitError} from "../common/form";
import {LoginFooter} from "./common";
import {Route} from "../common/link";
import {globalParams, redirectUrl} from "../../index";
import {SpringBackend} from "../../backend/spring";
import {AccountRequest} from "idaas-client";

export function PasswordLoginForm(props: { backend: SpringBackend }) {
    function getAccount(values: any): AccountRequest {
        switch (values.type as AccountType) {
            case "username":
                return {username: values.username};
            case "email":
                return {email: values.email};
            case "phone":
                return {nation: values.nation, phone: values.phone};
        }
        return {username: values.username}
    }

    return (
        <>
            <Route render={({history, location}) => (
                <FinalForm onSubmit={async (values: any) => {
                    try {
                        let result = await props.backend.passwordLogin({
                            namespace: getNamespace(values), ...getAccount(values)
                        }, values.password);
                        if (result) return {[FORM_ERROR]: result};
                        if (location.state && location.state.oauth) {
                            result = await props.backend.thirdBind();
                            if (result) return {[FORM_ERROR]: result}
                        }
                        window.location.href = redirectUrl(globalParams)
                    } catch (e) {
                        if (e instanceof NeedResetLoginError) {
                            history.push(`/reset/password${window.location.search}`, {
                                reset: {
                                    type: 'login',
                                }, ...location.state
                            })
                        } else if (e instanceof NeedMoreFactor) {
                            console.log(e);
                            return {[FORM_ERROR]: '需要多因子登录'};
                        } else {
                            console.log(e);
                            return {[FORM_ERROR]: '系统错误'}
                        }
                    }
                }}>
                    {({handleSubmit, values}) => (
                        <form onSubmit={handleSubmit}>
                            <FormTitle>密码登录</FormTitle>
                            <Form>
                                <NamespaceField namespaceOn={values.namespace_on}/>
                                <AccountField type={values.type}/>
                                <PasswordField field={{name: 'password'}} passwordType={'login'}/>
                                <FinalFormSubmitError/>
                            </Form>
                            <LoginFooter/>
                        </form>
                    )}
                </FinalForm>
            )}/>
        </>
    )
}
