import React from 'react'
import {Link, Redirect, RouteComponent} from "../common/link";
import {BackButton} from "../common/button";
import {withPageContext2} from "../common/context";

export const ResetEntry: RouteComponent = withPageContext2(({context: {disable_email, disable_sms}}) => <>
        {disable_email && !disable_sms && <Redirect to='/reset/sms'/>}
        {!disable_email && disable_sms && <Redirect to='/reset/email'/>}
    {!disable_sms && <p className="forget-password-item"><Link to="/reset/sms">通过短信重置密码</Link></p>}
    {!disable_email && <p className="forget-password-item"><Link to="/reset/email">通过邮箱重置密码</Link></p>}
    <p className="forget-password-item"><BackButton type='link'/></p>
</>);
