import React from 'react'
import {
    Link as RouterLink,
    LinkProps,
    Redirect as RouterRedirect,
    RedirectProps,
    Route as ReactRoute,
    RouteProps,
} from 'react-router-dom'
import {RouteChildrenProps, RouteComponentProps, StaticContext} from 'react-router'
import {History, Location, LocationDescriptor} from 'history'
import {RouteState} from "./routeState";

const removeParam = (search: string, key: string) => {
    const p = new URLSearchParams(search)
    p.delete(key)
    return p.toString()
}

type To = { to: LocationDescriptor<RouteState> }

function fillSearch<T extends To>(props: T, route: RouteComponentProps): T {
    let to: LinkProps['to'];
    if (typeof props.to === 'string') {
        to = {
            pathname: props.to,
            //保持search字段 去掉error参数
            search: removeParam(window.location.search, 'error'),
            //保持state
            state: route.location.state
        }
    } else {
        to = props.to
    }
    return {...props, to: to}
}

export const Link = (props: LinkProps & To) => <ReactRoute
    render={(route) => <RouterLink {...fillSearch(props, route)}/>}/>;

export const Redirect = (props: RedirectProps & To) => <ReactRoute
    render={(route) => <RouterRedirect {...fillSearch(props, route)}/>}/>;

export interface GenericRouteComponentProps<Params = {}> extends RouteComponentProps<Params, StaticContext, RouteState | undefined> {
    history: History<RouteState | undefined>
}

export interface GenericRouteChildrenProps<Params = {}> extends RouteChildrenProps<Params, RouteState | undefined> {
    history: History<RouteState | undefined>
}

export interface GenericRouteProps<Params extends { [K in keyof Params]?: string } = {}> extends RouteProps {
    location?: Location<RouteState | undefined>
    component?: React.ComponentType<GenericRouteComponentProps<Params>>
    render?: ((props: GenericRouteComponentProps<Params>) => React.ReactNode);
    children?: ((props: GenericRouteChildrenProps<Params>) => React.ReactNode) | React.ReactNode;
}

export const Route = <Params extends any>(props: GenericRouteProps<Params>) => <ReactRoute {...props}/>;

export type RouteComponent<Params = any> = GenericRouteProps<Params>['component']
export type RouteRenderProps<Params = any> = RouteComponentProps<Params, StaticContext, RouteState | undefined>
