import React from 'react'
import {BrowserRouter, Switch} from 'react-router-dom'
import {Card} from '@tencent/tea-component/lib/card'
import {Layout} from '@tencent/tea-component/lib/layout'
import {
    LayoutContent as Content,
    LayoutContentBody as Body,
    LayoutContentHeader as Header
} from '@tencent/tea-component/lib/layout/LayoutContent'
import {Col, Row} from '@tencent/tea-component/lib/grid'
import {globalParams, updateSettings as realUpdateSettings} from './index'
import '@tencent/tea-style/css/tea.css'
import {SpringBackend} from "./backend/spring";
import {Redirect, Route, RouteRenderProps} from "./component/common/link";
import {PasswordLoginForm} from "./component/login/password";
import {SmsLoginForm} from "./component/login/sms";
import {PasswordRegistrationForm} from "./component/registration/password";
import {ChangePasswordForm} from "./component/change_password";
import {ResetEntry} from "./component/reset/resetEntry";
import {SmsResetForm} from "./component/reset/sms";
import {EmailResetForm} from "./component/reset/email";
import {ResetPasswordForm} from "./component/reset/set_password";
import {Footer} from "./component/common/footer";
import {OAuth2CallbackPage, OAuthParameters} from "./component/login/oauth";
import {PageContext} from "./component/common/context";
import {isMobile, isTablet} from 'react-device-detect'
import {PasswordSmsLoginForm} from "./component/login/password_sms";
import {UserListForm} from "./component/login/nameList";

if (isMobile && !isTablet) import("./utils/mobile-css-wrap");
else import("./utils/pc-css-wrap");

let backend = new SpringBackend(globalParams.sdkappid, globalParams.captchaAid, globalParams.base_path);

const App = () => {
    const leftPad = 6;
    const width = (24 - leftPad * 2);
    let updateSettings = (params: any): boolean => {
        let updated = realUpdateSettings(params);
        if (updated) setPageContext({
            ...globalParams,
            updateSettings
        });
        if(backend.appId !== globalParams.sdkappid || backend.captchaAid !== globalParams.captchaAid || backend.basePath !== globalParams.base_path)
            backend = new SpringBackend(globalParams.sdkappid, globalParams.captchaAid, globalParams.base_path);
        return updated
    };
    let [pageContext, setPageContext] = React.useState({
        ...globalParams,
        updateSettings: updateSettings
    });
    return <PageContext.Provider value={pageContext}>
        <BrowserRouter basename={pageContext.base_path}>
            <Layout style={{display: pageContext.silent ? 'none' : undefined}}>
                <Header title={pageContext.title}/>
                <Content>
                    <Body>
                        <Row>
                            <Col span={leftPad}/>
                            <Col span={width}>
                                <Card>
                                    <Card.Body>
                                        <Switch>
                                            <Route path={["/", '/portal.html', '/static/portal.html']} exact
                                                   render={() => {
                                                       switch (pageContext.action) {
                                                           case "reg":
                                                               return <Redirect to="/registration"/>;
                                                           case "reset":
                                                               return <Redirect to="/reset"/>;
                                                           case "change_password":
                                                               return <Redirect to="/change/password"/>;
                                                           default:
                                                               return <Redirect to="/login"/>
                                                       }
                                                   }}/>
                                            <Route path="/login" exact render={() => {
                                                switch (pageContext.mode) {
                                                    case '2':
                                                        return <Redirect to="/login/sms"/>;
                                                    // case '3':
                                                    //     return <Redirect to="/login/email"/>;
                                                    case '4':
                                                        return <Redirect to="/login/password_sms"/>;
                                                    case '1':
                                                    default:
                                                        return <Redirect to="/login/password"/>;
                                                }
                                            }}/>
                                            <Route
                                                path="/login/oauth2/callback/:clientId?/:registrationId?"
                                                render={(props: RouteRenderProps<OAuthParameters>) =>
                                                    <OAuth2CallbackPage
                                                        backend={backend} route={props}/>}/>
                                            <Route path="/login/password"
                                                   render={() => <PasswordLoginForm backend={backend}/>}/>
                                            <Route path="/login/sms" render={() => <SmsLoginForm backend={backend}/>}/>
                                            {/*<Route path="/login/email" component={EmailLoginForm}/>*/}
                                            <Route path="/registration" exact render={() => {
                                                switch (pageContext.mode) {
                                                    // case '2':
                                                    //     return <Redirect to="/registration/sms"/>;
                                                    // case '3':
                                                    //     return <Redirect to="/registration/email"/>;
                                                    case '1':
                                                    default:
                                                        return <Redirect to="/registration/password"/>
                                                }
                                            }}/>
                                            <Route path="/registration/password"
                                                   render={(props) => <PasswordRegistrationForm
                                                       backend={backend} {...props}/>}/>
                                            {/*<Route path="/registration/sms"*/}
                                            {/*       render={() => <SmsRegistrationForm backend={backend}/>}/>*/}
                                            {/*<Route path="/registration/email" component={EmailRegistrationForm}/>*/}
                                            <Route path="/change/password" component={ChangePasswordForm}/>
                                            <Route path="/reset" exact render={() => {
                                                switch (pageContext.mode) {
                                                    case '2':
                                                        return <Redirect to="/reset/sms"/>;
                                                    case '3':
                                                        return <Redirect to="/reset/email"/>;
                                                    default:
                                                        return <Redirect to="/reset/entry"/>
                                                }
                                            }}/>
                                            <Route path="/reset/entry" component={ResetEntry}/>
                                            <Route path="/reset/sms" render={() => <SmsResetForm backend={backend}/>}/>
                                            <Route path="/reset/email"
                                                   render={() => <EmailResetForm backend={backend}/>}/>
                                            <Route path="/reset/password"
                                                   render={(props) => <ResetPasswordForm
                                                       backend={backend} {...props}/>}/>
                                            <Route path="/login/password_sms"
                                                   render={(props) => <PasswordSmsLoginForm
                                                        backend = {backend} {...props} /> }/>
                                            <Route path="/login/user_list"
                                                   render={(props) => <UserListForm
                                                       backend = {backend} {...props} />}/>
                                            <Route render={() => 404}/>
                                        </Switch>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Footer/>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Body>
                </Content>
            </Layout>
        </BrowserRouter>
    </PageContext.Provider>
};

export default App
