import React from 'react'
import {Form, FormTitle,} from '@tencent/tea-component/lib/form'
import {NeedMoreFactor, NeedResetLoginError} from "../../backend/backend";
import {Form as FinalForm} from 'react-final-form'
import {CodeField} from "../common/code";
import {FinalFormSubmitError, SetSubmitErrorMutator} from "../common/form";
import {FORM_ERROR} from "final-form";
import {getNamespace, NamespaceField} from "../common/namespace";
import {LoginFooter} from "./common";
import {CountryCodeField} from "../common/countryCode";
import {PhoneField} from "../common/phone";
import {Route} from "../common/link";
import {globalParams, redirectUrl} from "../../index";
import {SpringBackend} from "../../backend/spring";

const SmsLoginForm = (props: { backend: SpringBackend }) => {
    return (
        <Route render={({history, location}) =>
            <FinalForm onSubmit={async (values: any) => {
                try {
                    let result = await props.backend.smsLogin(values.nation, values.phone, values.code);
                    if (result) return {[FORM_ERROR]: result};
                    if (location.state && location.state.oauth) {
                        result = await props.backend.thirdBind();
                        if (result) return {[FORM_ERROR]: result}
                    }
                    window.location.href = redirectUrl(globalParams)
                } catch (e) {
                    if (e instanceof NeedResetLoginError) {
                        history.push(`/reset/password${window.location.search}`, {reset: {type: 'login'}, ...location.state})
                    } else if (e instanceof NeedMoreFactor) {
                        console.log(e);
                        return {[FORM_ERROR]: '需要多因子登录'};
                    } else {
                        console.log(e);
                        return {[FORM_ERROR]: '系统错误'}
                    }
                }
            }} mutators={{SetSubmitErrorMutator}}>
                {({handleSubmit, errors, values}) => (
                    <form onSubmit={handleSubmit}>
                        <FormTitle>短信登录</FormTitle>
                        <Form>
                            <NamespaceField namespaceOn={values.namespace_on}/>
                            <CountryCodeField field={{name: 'nation'}}/>
                            <PhoneField field={{name: 'phone'}}/>
                            <CodeField field={{name: 'code'}} input={{
                                buttonDisabled: errors.nation || errors.phone,
                                onSend: () => props.backend.smsLoginSend(getNamespace(values), values.nation, values.phone)
                            }}/>
                            <FinalFormSubmitError/>
                        </Form>
                        <LoginFooter/>
                    </form>
                )}
            </FinalForm>
        }>
        </Route>
    )
};
export {SmsLoginForm}
