import React from 'react'
import {Button, ButtonProps} from '@tencent/tea-component/lib/button'
import {useForm} from 'react-final-form'
import {Route} from "./link";

export const FinalFormSubmitButton = (props: ButtonProps) => {
    let form = useForm();
    return <Button htmlType="submit" type='primary' loading={form.getState().submitting} {...props}/>
};

export const BackButton = (props: ButtonProps) => <Route
    render={(route) => <Button htmlType={'button'} {...props} onClick={() => route.history.goBack()}>返回</Button>}/>;
