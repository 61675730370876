import React from 'react'
import {Segment, SegmentProps} from '@tencent/tea-component/lib/segment'
import {PhoneField} from './phone'
import {EmailField} from "./email";
import {AbstractFinalField, AbstractFinalFormItem, withFieldConfig, withFormItemConfig} from "./form";
import {InputFormItem} from "./inputFormItem";
import {CountryCodeField} from "./countryCode";
import {useForm} from 'react-final-form'
import {PageContextType, withPageContext2} from "./context";

const options = [
    {value: 'username', text: '用户名'},
    {value: 'email', text: '邮箱'},
    {value: 'phone', text: '手机号'},
];

export type AccountType = 'username' | 'email' | 'phone'

const filteredOptions = ({disable_sms, disable_email, disable_username}: PageContextType): SegmentProps['options'] =>
    options.filter(e => disable_sms ? e.value !== 'phone' : true)
        .filter(e => disable_email ? e.value !== 'email' : true)
        .filter(e => disable_username ? e.value !== 'username' : true);

export const UserTypeFormItem = withFormItemConfig<SegmentProps>(AbstractFinalFormItem(props =>
    <Segment {...props.input}/>), {
    formItem: {label: '用户类型', align: 'top'}
});

export const UserTypeField = withFieldConfig(AbstractFinalField(UserTypeFormItem), {
    field: {
        validate: (e: AccountType) => {
            switch (e) {
                case "username":
                case "email":
                case "phone":
                    break;
                default:
                    return '请选择用户类型'
            }
        }
    }
});

export const UserNameFormItem = withFormItemConfig(InputFormItem, {
    formItem: {label: '用户名'},
    input: {placeholder: '用户名', autoComplete: 'username'}
});

export const UserNameField = withFieldConfig(AbstractFinalField(UserNameFormItem), {
    field: {validate: (v: string) => v && v.length > 0 ? undefined : '请填写用户名'}
});

export const AccountField = withPageContext2<{ type: AccountType }>(({type, context}) => {
        let form = useForm();
    let accountOptions = filteredOptions(context)
        return <>
            <UserTypeField
                formItem={{style: {display: accountOptions.length > 1 ? undefined : "none"}}}
                field={{name: 'type', value: type, initialValue: filteredOptions(context)[0].value}}
                input={{
                    onChange: type => {
                        form.change('type', type);
                        switch (type) {
                            case "username":
                                form.change('username', '');
                                break;
                            case "email":
                                form.change('email', '');
                                break;
                            case "phone":
                                form.change('nation', '');
                                form.change('phone', '');
                                break;
                        }
                    },
                    options: accountOptions
                }}/>
            {(() => {
                switch (type) {
                    case "email":
                        return <EmailField field={{name: 'email'}}/>;
                    case "phone":
                        return <>
                            <CountryCodeField field={{name: 'nation'}}/>
                            <PhoneField field={{name: 'phone'}}/>
                        </>;
                    case "username":
                        return <UserNameField field={{name: 'username'}}/>
                }
            })()}
        </>
    }
);
