import React from 'react'
import {Form, FormAction, FormTitle,} from '@tencent/tea-component/lib/form'
import {UserNameField} from "../common/accountField";
import {PasswordField} from "../common/password";
import {Form as FinalForm} from 'react-final-form'
import {FORM_ERROR} from 'final-form'
import {BackButton, FinalFormSubmitButton} from "../common/button";
import {FinalFormSubmitError} from "../common/form";
import {GenericRouteComponentProps} from "../common/link";
import {globalParams, redirectUrl} from "../../index";
import {SpringBackend} from "../../backend/spring";

const PasswordRegistrationForm = (props: { backend: SpringBackend } & GenericRouteComponentProps) => {
    async function register(values: any) {
        let result = await props.backend.passwordReg(values.username, values.password);
        if (result) return {[FORM_ERROR]: result};
        if (props.location.state && props.location.state.oauth) {
            result = await props.backend.thirdBind();
            if (result) return {[FORM_ERROR]: result}
        }
        window.location.href = redirectUrl(globalParams)
    }

    return (
        <>
            <FinalForm onSubmit={register}>
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <FormTitle>密码注册</FormTitle>
                        <Form>
                            <UserNameField field={{name: 'username'}}/>
                            <PasswordField field={{name: 'password'}} passwordType={'set'}/>
                            <PasswordField field={{name: 'password2'}} passwordType={'check'} targetName={'password'}/>
                            <FinalFormSubmitError/>
                        </Form>
                        <FormAction>
                            <FinalFormSubmitButton>注册</FinalFormSubmitButton>
                            {props.location.state && <BackButton/>}
                        </FormAction>
                    </form>
                )}
            </FinalForm>
        </>
    )
};
export {PasswordRegistrationForm}
