import {SpringBackend} from "../../backend/spring";
import {GenericRouteComponentProps} from "../common/link";
import React, {useCallback, useEffect, useState} from "react";
import {Table} from "@tencent/tea-component/lib";
import {injectable, scrollable} from "@tencent/tea-component/lib/table/addons";
import {FORM_ERROR} from "final-form";
import {globalParams, redirectUrl} from "../../index";
import {Form as FinalForm} from "react-final-form";

export const UserListForm = (props: { backend: SpringBackend } & GenericRouteComponentProps) => {
    const [users, setUsers] = useState([]);
    const [loginType, setLoginType] = useState();

    const load = useCallback(
        async () => {
            let selectUserState = props.location.state && props.location.state.selectUser;
            if (!selectUserState) return {[FORM_ERROR]: '状态错误'};
            setLoginType(selectUserState.type)
            console.log(selectUserState.data.namespace);
            setUsers(selectUserState.data.namespace)
        },
        [props]
    );

    // keyword 变化重新加载
    useEffect(() => {
        load()
    }, [load]);

    return <>
        <FinalForm onSubmit={load}>
            {({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Table
                        columns={[
                            {
                                key: "namespaceRemark",
                                header: "学校名称",
                                width: 30,
                            },
                            {
                                key: "userIdRemark",
                                header: "用户昵称",
                                width: 30,
                            }
                        ]}
                        // @ts-ignore
                        records={users}
                        // @ts-ignore
                        recordKey="userId"
                        addons={[
                            // 支持表格滚动，高度超过 300 开始显示滚动条
                            scrollable({
                                maxHeight: 300,
                                minWidth: 120,
                                onScrollBottom: () => console.log("到达底部"),
                            }),

                            // 支持 props 注入
                            injectable({
                                row: (value, context) => ({
                                    onClick: async () => {
                                        let result;
                                        switch (loginType) {
                                            case 'open':
                                                result = await props.backend.thirdSelectNamespace(Number(context.record.namespace))
                                                break;
                                            case 'sms':
                                            case 'email':
                                            case 'password':
                                                //TODO
                                                break;
                                            default:
                                                return {[FORM_ERROR]: '状态错误'};
                                        }
                                        if (result) return {[FORM_ERROR]: result}
                                        window.location.href = redirectUrl(globalParams)
                                    },
                                }),
                            }),
                        ]}
                    />
                </form>
            )}
        </FinalForm>
    </>
};
