export interface GlobalParams {
    base_path: string
    lang: number
    action: string
    mode: string
    sdkappid: string
    captchaAid: string
    url: string
    disable_email: boolean
    disable_reg: boolean
    disable_sms: boolean
    disable_username: boolean
    disable_password: boolean
    disable_reset: boolean
    disable_sub_switch: boolean
    disable_country: boolean
    disable_owner_account_input: boolean
    disable_password_sms_factors: boolean
    default_sub_account_page: boolean
    default_owner_account: string
    title: string
    silent: boolean
    thirdConfig: { [index: string]: { logo: string } }
}

export let globalParams: GlobalParams;

function collectionParams(data?: any): GlobalParams {
    let query: URLSearchParams;
    if (!data) query = new URLSearchParams(window.location.search);
    else {
        let s = '?';
        for (let i in data) s += `${encodeURIComponent(i)}=${encodeURIComponent(data[i])}&`;
        query = new URLSearchParams(s)
    }
    let action = query.get('action') || '';
    switch (action.toLowerCase()) {
        case '':
        case 'reg':
        case 'reset':
        case 'change_password':
            action = action.toLowerCase();
            break;
        default:
            action = '';
            break;
    }
    let mode = query.get('mode') || '1';
    switch (mode) {
        case '1': // 密码
        case '2': // 短信
        case '4': // 短信 + 密码双因子登录
            break;
        default:
            mode = '1';
            break
    }
    let thirdConfigValue = query.get('third') || '';
    let thirdConfig: GlobalParams['thirdConfig'];
    try {
        thirdConfig = (thirdConfigValue !== '') ? JSON.parse(thirdConfigValue) : {};
    } catch (e) {
        thirdConfig = {};
        console.log('parse third config json failed', e)
    }
    let params: GlobalParams = {
        base_path: query.get('base-path') || '',
        lang: 2052, // 目前只支持中文
        sdkappid: query.get('sdkappid') || '',
        captchaAid: query.get('captchaAid') || '',
        url: query.get('url') || '',
        disable_email: (query.get('disable-email') === "1"),
        disable_reg: (query.get('disable-reg') === "1"),
        disable_sms: (query.get('disable-sms') === "1"),
        disable_username: (query.get('disable-username') === "1"),
        disable_password: (query.get('disable-password') === "1"),
        disable_reset: (query.get('disable-reset') === "1"),
        disable_sub_switch: (query.get('disable-sub-account-switch') === "1"),
        disable_country: (query.get('disable-country') === "1"),
        disable_owner_account_input: (query.get('disable-owner-account-input') === "1"),
        disable_password_sms_factors: (query.get('disable_password_sms_factors') === "1"),
        default_sub_account_page: (query.get('default-sub-account-page') === "1"),
        default_owner_account: query.get('default-owner-account') || "",
        title: query.get("title") || (window.location.pathname.startsWith("/login/oauth2/callback") ? "" : "腾讯登录服务"),
        silent: query.get("silent") === "1",
        thirdConfig: thirdConfig,
        action: action,
        mode: mode,
    };
    document.title = params.title;
    if (params.disable_reg && params.action === "reg") params.action = "";
    if (params.disable_sms && params.mode === '2') params.mode = '1';
    if (params.disable_password && params.mode === '1') params.mode = '2';
    return params
}

export const updateSettings = (data?: any): boolean => {
    let params = collectionParams(data);
    let updated = JSON.stringify(params) !== JSON.stringify(globalParams);
    globalParams = params;
    return updated
};
updateSettings(); //初始化时先用url来查一次
if (window !== window.parent) {
    document.body.style.overflow = 'hidden'
}

export function redirectUrl(params: GlobalParams): string {
    if (!params.base_path) return params.url;
    let url = new URL(params.url);
    if (url.protocol === window.location.protocol &&
        url.host === window.location.host &&
        url.pathname === "/oauth/authorize") {
        url.pathname = params.base_path + "/oauth/authorize";
        return url.toString()
    }
    return params.url;
}
