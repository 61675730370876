export class NotRegistrationError extends Error {
    constructor(message?: string) {
        super(message ? message : '帐号未注册')
    }
}

export class NeedResetLoginError extends Error {
    constructor(message?: string) {
        super(message ? message : "请重置密码");
    }
}

export class NeedMoreFactor extends Error {
    constructor(message?: string) {
        super(message ? message : "需要更多因子登录");
    }
}

export class AskCodeResponse {
    error?: string;
    countDown?: number
}

export interface CallbackSettings {
    type: 'code' | 'token'
    registrationId: string
    allowRegWithOAuth: boolean
    allowRegWithSelf: boolean
    allowBind: boolean
    pageSettings: any
}

export interface TokenCallbackSettings extends CallbackSettings {
    type: 'token'
    tokenName: string
    extraParameterNames: string[]
}

export interface CodeCallbackSettings extends CallbackSettings {
    type: 'code'
    codeName?: string | null
    extraParameterNames: string[]
}

export interface SettingsRequest {
    clientId: string,
    registrationId: string,
    redirectUri?: string | null,
    responseType?: string | null
}
