import {InputFormItem} from "./inputFormItem";
import {AbstractFinalField, FinalFieldProps, withFieldConfig, withFormItemConfig} from "./form";
import {PageContextType, withPageContext2} from "./context";
import {InputProps} from "@tencent/tea-component/lib";

const validatePhone = (e: string) => /^\d+$/.test(e) ? undefined : '请输入有效的手机号';

export const Phone = withFormItemConfig(InputFormItem, {
    formItem: {
        label: '手机号',
    },
    input: {
        placeholder: '手机号',
        autoComplete: 'tel-national',
    },
});

export const PhoneField = withPageContext2(withFieldConfig<InputProps, HTMLElement, string, FinalFieldProps<InputProps> & { context: PageContextType }>(AbstractFinalField(Phone), ({context: {disable_country}}) => ({
    field: {validate: validatePhone},
    formItem: {
        className: disable_country ? "" : "phone-number"
    }
})));
