import React, {useState} from 'react'
import {Input, InputProps} from '@tencent/tea-component/lib/input'
import {AbstractFinalFormItem} from "./form"
import {AutoComplete, AutoCompleteProps} from '@tencent/tea-component/lib/autocomplete'
import {List, ListItem} from '@tencent/tea-component/lib/list'
import {Layout} from "@tencent/tea-component/lib/layout";
import {LayoutContent as Content, LayoutContentBody as Body} from "@tencent/tea-component/lib/layout/LayoutContent";
import {Dropdown} from "@tencent/tea-component/lib/dropdown";

export const InputFormItem = AbstractFinalFormItem<InputProps>(props => <Input {...props.input}/>);

export const InputAutoCompleteFormItem = AbstractFinalFormItem<InputProps & AutoCompleteProps>(props =>
    <AutoComplete {...props.input}>
        {ref => <Input ref={ref} {...props.input}/>}
    </AutoComplete>);

export const MobileInputAutoCompleteFormItem = AbstractFinalFormItem<InputProps & AutoCompleteProps>(props => {
    const [opened, setOpen] = useState(false);
    return <div className="tea-input-group__addon">
        <Dropdown appearence="button" button={props.input.value} children={""}
                  onOpen={() => setOpen(true)}/>
        {opened && <Layout>
            <Content>
                <Body>
                    <section className="select-country">
                        <div onClick={() => setOpen(false)} className="close">
                            <i className="tea-icon tea-icon-close"/>
                        </div>
                        <div className="title">选择国家码</div>
                        <div className="country-list">
                            <List split="divide">
                                {
                                    // @ts-ignore
                                    props.input.options.map(it => <ListItem key={it.value} onClick={() => {
                                        setOpen(false);
                                        props.input.onChange(it.value)
                                    }}>{it.text}</ListItem>)
                                }
                            </List>
                        </div>
                    </section>
                </Body>
            </Content>
        </Layout>}
    </div>
});
