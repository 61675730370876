import {
    AskCodeResponse,
    CallbackSettings,
    NeedMoreFactor,
    NeedResetLoginError,
    NotRegistrationError,
    SettingsRequest
} from "./backend";
import {AccountRequest, SimpleIDaaSClient, SimpleIDaaSClientException} from "idaas-client";

const ERR_NEED_RESET_PASSWORD = 12050;
const ERR_NEED_MORE_FACTORS = 12036;
const ERR_NEED_SELECT_NAMESPACE = 12082;

function getErrorMessage(response: any): string {
    if (typeof response === "string") {
        return response
    } else {
        return response.message ? response.message : "系统错误"
    }
}

export class SpringBackend {
    constructor(appId: string, captchaAid: string, basePath: string) {
        this.appId = appId;
        this.captchaAid = captchaAid;
        this.basePath = basePath;
        this.client = new SimpleIDaaSClient({
            appId: appId,
            captchaAppId: captchaAid === "" || captchaAid === "0" ? undefined : captchaAid,
            basePath: basePath
        })
    }

    private client: SimpleIDaaSClient;
    public readonly appId: string;
    public readonly captchaAid: string;
    public readonly basePath: string;

    async loginReset(oldPassword: string, newPassword: string): Promise<string | void> {
        return this.client.loginResetPassword(oldPassword, newPassword)
            .then(() => {
                },
                reason => getErrorMessage(reason));
    }

    loginResetNeedOldPassword(): boolean {
        return this.client.loginResetNeedOldPassword()
    }

    async loginSet(password: string): Promise<string | void> {
        return this.client.loginSetPassword(password)
            .then(() => {
            }, reason => getErrorMessage(reason))
    }

    async changePassword(request: AccountRequest, password: string): Promise<string | void> {
        return this.client.changePasswordSubmitOld(request, password)
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async changePassword2(password: string): Promise<string | void> {
        return this.client.changePasswordSubmitNew(password)
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async mailResetSend(email: string): Promise<AskCodeResponse> {
        return this.client.emailResetSend({email: email})
            .then(response => ({
                    countDown: response.data && response.data.resendTime ? response.data.resendTime : 60
                }),
                reason => ({
                    error: getErrorMessage(reason)
                }));
    }

    async mailReset(email: string, code: string): Promise<string | void> {
        return this.client.emailResetSubmit(code)
            .then(response => {
                },
                reason => getErrorMessage(reason));
    }

    async mailReset2(password: string): Promise<string | void> {
        return this.client.emailResetSetPassword(password)
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async mailLoginSend(namespace: string | undefined, email: string): Promise<AskCodeResponse> {
        return this.client.emailLoginSend({namespace, email})
            .then(response => ({
                countDown: response.data && response.data.resendTime ? response.data.resendTime : 60
            }),
                reason => ({error: getErrorMessage(reason)}));
    }

    async mailLogin(email: string, code: string): Promise<string | void> {
        return this.client.emailLoginSubmit(code)
            .then(response => {
                if (response.code === ERR_NEED_RESET_PASSWORD) throw new NeedResetLoginError();
                else if (response.code === ERR_NEED_MORE_FACTORS) throw new NeedMoreFactor();
            }, reason => getErrorMessage(reason));
    }

    async passwordLogin(request: AccountRequest, password: string): Promise<string | void> {
        return this.client.passwordLogin(request, password)
            .then(response => {
                if (response.code === ERR_NEED_RESET_PASSWORD) throw new NeedResetLoginError();
                else if (response.code === ERR_NEED_MORE_FACTORS) throw new NeedMoreFactor();
            }, reason => getErrorMessage(reason));
    }

    async passwordReg(username: string, password: string): Promise<string | void> {
        return this.client.passwordReg({username: username}, password)
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async smsLoginSend(namespace: string | undefined, nation: string, phone: string): Promise<AskCodeResponse> {
        return this.client.smsLoginSend({namespace, nation, phone})
            .then(response => ({
                    countDown: response.data && response.data.resendTime ? response.data.resendTime : 60
                }),
                reason => ({error: getErrorMessage(reason)}));
    }

    async smsLogin(nation: string, phone: string, code: string): Promise<string | void> {
        return this.client.smsLoginSubmit(code)
            .then(response => {
                if (response.code === ERR_NEED_RESET_PASSWORD) throw new NeedResetLoginError();
                else if (response.code === ERR_NEED_MORE_FACTORS) throw new NeedMoreFactor();
            }, reason => getErrorMessage(reason));
    }

    async smsRegSend(nation: string, phone: string): Promise<AskCodeResponse> {
        return this.client.smsRegSend({nation, phone})
            .then(response => ({countDown: response.data && response.data.resendTime ? response.data.resendTime : 60}), reason => ({
                error: getErrorMessage(reason)
            }))
    }

    async smsReg(nation: string, phone: string, code: string): Promise<void | string> {
        return this.client.smsRegSubmit(code).then(response => {
        }, reason => getErrorMessage(reason));
    }

    async smsResetSend(nation: string, phone: string): Promise<AskCodeResponse> {
        return this.client.smsResetSend({nation, phone})
            .then(response => ({countDown: response.data && response.data.resendTime ? response.data.resendTime : 60}), reason => ({
                error: getErrorMessage(reason)
            }));
    }

    async smsReset(nation: string, phone: string, code: string): Promise<string | void> {
        return this.client.smsResetSubmit(code)
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async smsReset2(password: string): Promise<string | void> {
        return this.client.smsResetSetPassword(password)
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async thirdCode(code: string, registrationId: string): Promise<string | void> {
        return this.client.thirdCode(code, registrationId)
            .then(response => {
            }, reason => getErrorMessage((reason)))
    }

    async thirdLogin(namespace?: number): Promise<string | void> {
        return this.client.thirdLogin(namespace)
            .then(response => {
                if (response.code === ERR_NEED_RESET_PASSWORD) throw new NeedResetLoginError();
                else if (response.code === ERR_NEED_MORE_FACTORS) throw new NeedMoreFactor();
            }, reason => {
                if (reason instanceof SimpleIDaaSClientException && reason.response && reason.response.code === 12008)
                    throw new NotRegistrationError(reason.response.message);
                return getErrorMessage(reason)
            });
    }

    async thirdLoginWithoutNamespace(): Promise<any> {
        return this.client.thirdLoginWithoutNamespace()
            .then(response => {
                if (response.code === ERR_NEED_RESET_PASSWORD) throw new NeedResetLoginError();
                else if (response.code === ERR_NEED_MORE_FACTORS) throw new NeedMoreFactor();
                else if (response.code === ERR_NEED_SELECT_NAMESPACE) { return response.data }
            }, reason => {
                if (reason instanceof SimpleIDaaSClientException && reason.response && reason.response.code === 12008)
                    throw new NotRegistrationError(reason.response.message);
                return getErrorMessage(reason)
            })
    }

    async thirdSelectNamespace(namespace: number): Promise<string | void> {
        return this.client.thirdLoginSelectNamespace(namespace)
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async thirdReg(): Promise<string | void> {
        return this.client.thirdReg()
            .then(response => {
            }, reason => {
                return getErrorMessage(reason)
            });
    }

    async thirdCheckToken(registrationId: string, token: string, extraParams: any): Promise<string | void> {
        return this.client.thirdCheckToken(registrationId, token, extraParams)
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async thirdBind(): Promise<string | void> {
        return this.client.thirdBind()
            .then(response => {
            }, reason => getErrorMessage(reason));
    }

    async getThirdSettings(params?: SettingsRequest): Promise<CallbackSettings | string> {
        let response;
        if (!params)
            response = await this.client.get('/v1/account/oauth2/callback_settings');
        else {
            let query = [];
            params.redirectUri && query.push(`redirect_uri=${encodeURIComponent(params.redirectUri)}`);
            params.responseType && query.push(`response_type=${encodeURIComponent(params.responseType)}`);
            response = await this.client.get(`/v1/account/oauth2/callback_settings/${params.clientId}/${params.registrationId}?${query.join('&')}`)
        }
        if (response.code === 0) {
            return response.data;
        } else {
            return response.message ? response.message : `系统错误 ${response.code}`
        }
    }
}
