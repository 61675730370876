import React, {MouseEvent as ReactMouseEvent} from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {
    CallbackSettings,
    CodeCallbackSettings,
    NeedMoreFactor,
    NeedResetLoginError,
    NotRegistrationError,
    TokenCallbackSettings
} from "../../backend/backend";
import {ErrorTip, StatusTip} from '@tencent/tea-component/lib/tips'
import {Link, Redirect} from "../common/link";
import {withPageContext2} from "../common/context";
import {globalParams, redirectUrl} from "../../index";
import {SpringBackend} from "../../backend/spring";
import {FORM_ERROR} from "final-form";

let hash = () => {
    let h = window.location.hash;
    if (!h) return '';
    return h.substr(1, h.length)
};

export interface OAuthParameters {
    registrationId: string
    clientId: string
}

export const OAuth2CallbackPage = withPageContext2<{ backend: SpringBackend, route: RouteComponentProps<OAuthParameters> }>(({context: {disable_reg, updateSettings}, ...props}) => {
    let params = new URLSearchParams(window.location.search);
    let [error, setError] = React.useState<string | null>(params.get('error'));
    let [setting, setSetting] = React.useState<CallbackSettings | null>(null);
    React.useEffect(() => {
        if (error || setting) return;
        let process = async (setting: CallbackSettings) => {
            try {
                // @ts-ignore
                let response = await props.backend.thirdLoginWithoutNamespace();
                if (response && response.namespace) {
                    props.route.history.push(`/login/user_list${window.location.search}`, {selectUser: {type: 'open', data: response}, ...props.route.location.state})
                    return
                }
                if (response) setError(response);
                window.location.href = redirectUrl(globalParams)
            } catch (e) {
                if (e instanceof NeedResetLoginError) {
                    props.route.history.push(`/reset/password${window.location.search}`, {
                        reset: {
                            type: 'login'
                        }, ...props.route.location.state
                    })
                } else if (e instanceof NotRegistrationError) {
                    if (!(setting.allowBind || setting.allowRegWithOAuth || setting.allowRegWithSelf)) {
                        setError(e.message)
                    }
                    setSetting(setting)
                } else if (e instanceof NeedMoreFactor) {
                    console.log(e);
                    return {[FORM_ERROR]: '需要多因子登录'};
                } else {
                    console.log(e);
                    setError('系统错误')
                }
            }
        };
        let processToken = async (setting: TokenCallbackSettings): Promise<boolean> => {
            let queryParams = new URLSearchParams(window.location.search);
            let hashParams = new URLSearchParams(hash());
            let get = (name: string) => hashParams.get(name) || queryParams.get(name);
            let token = get(setting.tokenName);
            if (!token) {
                setError(`缺少参数${setting.tokenName}`);
                return false
            }
            let extraParams: any = {};
            for (let parameterName of setting.extraParameterNames) {
                extraParams[parameterName] = get(setting.tokenName);
                if (!extraParams[parameterName]) {
                    setError(`缺少参数${parameterName}`);
                    return false
                }
            }
            let response = await props.backend.thirdCheckToken(setting.registrationId, token, extraParams);
            if (response) {
                setError(response);
                return false
            }
            return true
        };
        let processCode = async (setting: CodeCallbackSettings): Promise<boolean> => {
            let queryParams = new URLSearchParams(window.location.search);
            let hashParams = new URLSearchParams(hash());
            let get = (name: string) => hashParams.get(name) || queryParams.get(name);
            let code = get(setting.codeName ? setting.codeName : "code");
            if (!code) {
                return false
            }
            let response = await props.backend.thirdCode(code, setting.registrationId);
            if (response) {
                setError(response);
                return false
            }
            return true
        };
        let processSettings = async () => {
            //如果带参数来了
            if (props.route.match.params.clientId && props.route.match.params.registrationId) {
                return await props.backend.getThirdSettings({
                    clientId: props.route.match.params.clientId,
                    registrationId: props.route.match.params.registrationId,
                    redirectUri: params.get('redirect_uri'),
                    responseType: params.get('response_type')
                })
            } else {
                return await props.backend.getThirdSettings()
            }
        };
        (async () => {
            let response = await processSettings();
            if (typeof response === 'string') {
                setError(response);
                return
            }
            //如果更新成功 就会rerender
            if (updateSettings(response.pageSettings)) {
                console.log('update settings');
                return
            } else (
                console.log('not update settings')
            );
            if (response.type === 'code') {
                if (props.route.match.params.clientId && props.route.match.params.registrationId) {
                    if (await processCode(response as CodeCallbackSettings)) {
                        await process(response)
                    } else {
                        setError('系统错误')
                    }
                } else {
                    await process(response)
                }
            } else if (response.type === 'token') {
                if (await processToken(response as TokenCallbackSettings)) {
                    await process(response)
                }
            } else {
                setError('系统错误')
            }
        })()
    });
    return <>
        {(() => {
            if (error) return <ErrorTip errorText={error} retryText=''/>;
            if (!setting) return <StatusTip status='loading'/>;
            const loginTo = {pathname: '/login', state: {oauth: {}}}
            const regSelfTo = {pathname: '/registration', state: {oauth: {}}}
            const regOAuthFunc = (e?: ReactMouseEvent<Element, MouseEvent>) => {
                e && e.preventDefault()
                props.backend.thirdReg().then(response => {
                    if (response) {
                        setError(response)
                        return
                    }
                    window.location.href = redirectUrl(globalParams)
                })
            }
            let list = [
                {
                    condition: () => setting && setting.allowBind,
                    dom: <p key="bind" className="btn-item"><Link to={loginTo}>登录已有帐号进行绑定</Link></p>,
                    operate: <Redirect to={loginTo}/>
                },
                {
                    condition: () => setting && setting.allowRegWithOAuth && !disable_reg,
                    dom: <p key="reg_oauth" className="btn-item"><a href="/" onClick={regOAuthFunc}>创建新帐号</a></p>,
                    operate: regOAuthFunc
                },
                {
                    condition: () => setting && setting.allowRegWithSelf && !disable_reg,
                    dom: <p key="reg_self" className="btn-item"><Link to={regSelfTo}>注册帐号并绑定</Link></p>,
                    operate: <Redirect to={regSelfTo}/>
                }
            ].filter(e => e.condition())
            if (list.length === 1) {
                if (typeof list[0].operate === "function") {
                    list[0].operate()
                    return null
                } else {
                    return list[0].operate
                }
            } else {
                return <>{list.map(e => e.dom)}</>
            }
        })()}
    </>
});
