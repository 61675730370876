import React from 'react'
import {Form, FormAction, FormTitle} from '@tencent/tea-component/lib/form'
import {Form as FinalForm} from 'react-final-form'
import {FinalFormSubmitError, SetSubmitErrorMutator} from "../common/form";
import {CodeField} from "../common/code";
import {FORM_ERROR} from "final-form";
import {BackButton, FinalFormSubmitButton} from "../common/button";
import {CountryCodeField} from "../common/countryCode";
import {PhoneField} from "../common/phone";
import {Route} from "../common/link";
import {SpringBackend} from "../../backend/spring";

const SmsResetForm = (props: { backend: SpringBackend }) => {
    return (
        <>
            <Route render={({history}) => (
                <FinalForm onSubmit={async (values: any) => {
                    let result = await props.backend.smsReset(values.nation, values.phone, values.code);
                    if (result) return {[FORM_ERROR]: result};
                    history.push(`/reset/password${window.location.search}`, {reset: {type: 'sms'}})
                }} mutators={{SetSubmitErrorMutator}}>
                    {({handleSubmit, errors, values}) => (
                        <form onSubmit={handleSubmit}>
                            <FormTitle>短信重置密码</FormTitle>
                            <Form>
                                <CountryCodeField field={{name: 'nation'}}/>
                                <PhoneField field={{name: 'phone'}}/>
                                <CodeField field={{name: 'code'}} input={{
                                    buttonDisabled: errors.nation || errors.phone,
                                    onSend: () => props.backend.smsResetSend(values.nation, values.phone)
                                }}/>
                                <FinalFormSubmitError/>
                            </Form>
                            <FormAction>
                                <FinalFormSubmitButton>下一步</FinalFormSubmitButton>
                                <BackButton/>
                            </FormAction>
                        </form>
                    )}
                </FinalForm>
            )}/>
        </>
    )
};
export {SmsResetForm}
