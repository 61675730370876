import React from 'react'
import {
    AbstractFinalFormItem,
    FinalFieldProps,
    FinalFormItemProps,
    getMessage,
    getStatus,
    withFormItemConfig
} from "./form";
import {Input, InputProps} from '@tencent/tea-component/lib/input'
import {Button, ButtonProps} from '@tencent/tea-component/lib/button'
import {AskCodeResponse} from "../../backend/backend";
import {Field, FieldInputProps, useForm} from "react-final-form";
import {AsyncOrSync, Merge} from 'ts-essentials'

interface CodeButtonProps extends InputProps {
    onSend: () => AsyncOrSync<AskCodeResponse>
    onButtonClick: ButtonProps['onClick']
    buttonLoading: ButtonProps['loading']
    buttonChildren: ButtonProps['children']
    buttonDisabled: ButtonProps['disabled']
}

export const CodeFormItem: React.ComponentType<FinalFormItemProps<CodeButtonProps>> = withFormItemConfig(AbstractFinalFormItem<CodeButtonProps>(props => {
    let {onSend, onButtonClick, buttonLoading, buttonChildren, buttonDisabled, ...inputProps} = props.input;
    return <>
        <Input {...inputProps}/>
        <Button htmlType='button' loading={buttonLoading} onClick={onButtonClick} disabled={buttonDisabled}>
            {buttonChildren}
        </Button>
    </>
}), {
    formItem: {label: '验证码', align: 'top', className: 'verification-code'},
    input: {
        placeholder: '验证码', size: 's',
        buttonChildren: '发送验证码'
    }
});

export const CodeField = (props: FinalFieldProps<CodeButtonProps>) => {
    let [countdown, setCountdown] = React.useState(0);
    let [sending, setSending] = React.useState(false);
    let form = useForm();
    let setError = form.mutators['SetSubmitErrorMutator'];
    if (!setError) throw new Error('should add mutator SetSubmitErrorMutator');

    React.useEffect(() => {
        let timer = setInterval(() => (setCountdown(count => count > 0 ? count - 1 : count)), 1000);
        return () => clearInterval(timer)
    }, []);

    function processCountDown(response: AskCodeResponse) {
        if (response.countDown) setCountdown(response.countDown);
        else if (response.error) setError(response.error);
        else setError('系统错误')
    }

    let {buttonDisabled, ...propsInput} = props.input!;
    return <Field validate={value => value && value.length > 0 ? undefined : '请输入验证码'} {...props.field}>
        {({input, meta}) => (
            <CodeFormItem
                formItem={{status: getStatus(meta), message: getMessage(meta), ...props.formItem}}
                input={{
                    buttonLoading: sending,
                    buttonDisabled: sending || countdown !== 0 || buttonDisabled,
                    buttonChildren: countdown === 0 ? '发送验证码' : countdown,
                    onButtonClick: () => {
                        if (!propsInput || !propsInput.onSend) return console.error('has no onSend');
                        let response = propsInput.onSend();
                        if ((response as PromiseLike<AskCodeResponse>).then) {
                            setSending(true);
                            response = response as PromiseLike<AskCodeResponse>;
                            response.then(response => {
                                setSending(false);
                                processCountDown(response)
                            }, (reason => {
                                setSending(false);
                                console.log(reason);
                                setError('系统错误')
                            }))
                        } else {
                            response = response as AskCodeResponse;
                            processCountDown(response)
                        }
                    },
                    ...input, ...propsInput! as Merge<CodeButtonProps, FieldInputProps<any, HTMLElement>>
                }}/>
        )}
    </Field>
};
