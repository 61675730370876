import React from 'react'
import {Form, FormAction, FormItem, FormTitle} from '@tencent/tea-component/lib/form'
import {Input} from '@tencent/tea-component/lib/input'
import {FinalFormSubmitButton} from "./common/button";
import {FinalFormSubmitError} from "./common/form";

const ChangePasswordForm: React.FC = () => {
    return (
        <>
            <FormTitle>设置密码</FormTitle>
            <Form>
                {/*TODO 用户名/邮箱/手机号*/}
                <FormItem label="密码" required>
                    <Input placeholder="密码"/>
                </FormItem>
                <FormItem label="新密码" required>
                    <Input placeholder="新密码"/>
                </FormItem>
                <FormItem label="确认密码" required>
                    <Input placeholder="确认密码"/>
                </FormItem>
                <FinalFormSubmitError/>
            </Form>
            <FormAction>
                <FinalFormSubmitButton>重置</FinalFormSubmitButton>
            </FormAction>
        </>
    )
};
export {ChangePasswordForm}
