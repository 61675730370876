import React from 'react'
import {Tabs} from "@tencent/tea-component/lib/tabs";
import {AbstractFinalField, FinalFormItemProps, withFieldConfig, withFormItemConfig} from "./form";
import {InputFormItem} from "./inputFormItem";
import {withPageContext2} from "./context";
import {SwitchProps} from "@tencent/tea-component/lib";


export const NamespaceSwitchFormItem =
    (props: FinalFormItemProps<SwitchProps>) => <Tabs
        tabs={[{id: "owner", label: "主帐号登录"}, {id: "sub", label: "子帐号登录"}]}
        activeId={props.input.value ? "sub" : "owner"}
        defaultActiveId={props.input.defaultValue ? "sub" : "owner"}
        onActive={tab => props.input.onChange(tab.id === "sub")}
        style={props.formItem.style}/>;

export const NamespaceSwitchField = AbstractFinalField(NamespaceSwitchFormItem);

export const NamespaceInputFormItem = withFormItemConfig(InputFormItem, {
    formItem: {label: '主帐号ID'},
    input: {placeholder: '主帐号ID'}
});
export const NamespaceInputField = withFieldConfig(AbstractFinalField(NamespaceInputFormItem), {
    field: {validate: v => v && /^\d+$/.test(v) ? undefined : '请填写有效的主帐号ID'}
});

export const NamespaceField = withPageContext2<{ namespaceOn: boolean }>(
    ({
         namespaceOn, context: {
            default_owner_account, default_sub_account_page,
            disable_sub_switch, disable_owner_account_input
        }
     }) => <>
        <NamespaceSwitchField
            field={{name: 'namespace_on', initialValue: default_sub_account_page}}
            formItem={{
                style: {display: disable_sub_switch ? 'none' : undefined}
            }}
        />
        {namespaceOn && <NamespaceInputField
            field={{name: 'namespace', initialValue: default_owner_account}}
            formItem={{style: {display: disable_owner_account_input ? 'none' : undefined}}}/>}
    </>);

export function getNamespace(values: any) {
    return values.namespace_on ? values.namespace : undefined
}

