import React from 'react'
import {GlobalParams, globalParams, updateSettings} from '../../index'

export type PageContextType = GlobalParams & { updateSettings: typeof updateSettings }
export const PageContext: React.Context<PageContextType> = React.createContext({
    ...globalParams,
    updateSettings: updateSettings
});

export function withPageContext<T>(wrap: (context: PageContextType) => React.ComponentType<T>): React.ComponentType<T> {
    return (props: T) => <PageContext.Consumer>
        {value => {
            let Component = wrap(value);
            return <Component {...props}/>
        }}
    </PageContext.Consumer>
}

export function withPageContext2<P>(Component: React.ComponentType<P & { context: PageContextType }>): React.ComponentType<P> {
    return props => <PageContext.Consumer>
        {value => <Component {...props} context={value}/>}
    </PageContext.Consumer>
}
