import React from 'react'
import {Form, FormAction, FormTitle} from '@tencent/tea-component/lib/form'
import {Form as FinalForm} from 'react-final-form'
import {PasswordField} from "../common/password";
import {FORM_ERROR} from 'final-form'
import {FinalFormSubmitButton} from "../common/button";
import {FinalFormSubmitError} from "../common/form";
import {GenericRouteComponentProps} from "../common/link";
import {globalParams, redirectUrl} from "../../index";
import {SpringBackend} from "../../backend/spring";

export const ResetPasswordForm = (props: { backend: SpringBackend } & GenericRouteComponentProps) => {
    let resetState = props.location.state && props.location.state.reset;
    async function reset(values: any) {
        let result;
        if (!resetState) return {[FORM_ERROR]: '状态错误'};
        switch (resetState.type) {
            case 'sms':
                result = await props.backend.smsReset2(values.password);
                break;
            case 'email':
                result = await props.backend.mailReset2(values.password);
                break;
            case "login":
                if (props.backend.loginResetNeedOldPassword()) {
                    result = await props.backend.loginReset(values.old_password, values.password);
                } else {
                    result = await props.backend.loginSet(values.password);
                }
                break;
            default:
                result = '系统错误';
                break
        }
        if (result) return {[FORM_ERROR]: result};
        if (props.location.state && props.location.state.oauth) {
            result = await props.backend.thirdBind();
            if (result) return {[FORM_ERROR]: result}
        }
        window.location.href = redirectUrl(globalParams)
    }

    return <>
        <FinalForm onSubmit={reset}>
            {({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <FormTitle>设置密码</FormTitle>
                    <Form>
                        {
                            //如果非密码登录后重置密码不会带旧密码过来 需要输入旧密码
                            resetState && resetState.type === 'login' && props.backend.loginResetNeedOldPassword() &&
                            <PasswordField field={{name: 'old_password'}} passwordType={'login'} formItem={{label: "旧密码"}}/>
                        }
                        <PasswordField field={{name: 'password'}} passwordType={'set'}/>
                        <PasswordField field={{name: 'password2'}} passwordType={'check'} targetName={'password'}/>
                        <FinalFormSubmitError/>
                    </Form>
                    <FormAction>
                        <FinalFormSubmitButton>重置</FinalFormSubmitButton>
                    </FormAction>
                </form>
            )}
        </FinalForm>
    </>

};
