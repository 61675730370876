import {AbstractFinalField, FinalFieldProps, withFieldConfig, withFormItemConfig} from "./form";
import {InputFormItem} from "./inputFormItem";
import {InputProps} from '@tencent/tea-component/lib/input'

export const validatePasswordFormItemForSet = (password?: string): any => {
    if (!password || password.length === 0) {
        return '请填写密码'
    }
    if (password.length < 8) {
        return '用户密码至少需要8个字符'
    }
    if (password.length > 16) {
        return '用户密码最多16个字符'
    }
    let hasNumber = /\d/.test(password);
    let hasAlpha = /[a-z]/i.test(password);
    let hasSpecial = /[^a-z0-9]/i.test(password);
    if (+hasNumber + +hasAlpha + +hasSpecial < 2) {
        return '密码必须包含数字、字母、字符两种组合'
    }
    return
};

export const validatePasswordFormItemForLogin = (password?: string): any => {
    if (!password || password.length === 0) {
        return '请填写密码'
    }
    return
};

interface PasswordFieldProps {
    passwordType: 'login' | 'set' | 'check'
    targetName?: string
}

export const Password = withFormItemConfig(InputFormItem, {
    formItem: {
        label: '密码',
    },
    input: {
        placeholder: '密码',
        type: 'password',
        autoComplete: 'current-password',
    },
});

export const PasswordField = withFieldConfig<InputProps, HTMLElement, string, FinalFieldProps<InputProps, HTMLElement> & PasswordFieldProps>(AbstractFinalField(Password), props => {
    switch (props.passwordType) {
        case "login":
            return {field: {validate: validatePasswordFormItemForLogin, type: 'password'}};
        case "set":
            return {
                field: {validate: validatePasswordFormItemForSet, type: 'password'},
                input: {autoComplete: 'new-password'}
            };
        case "check":
            return {
                field: {
                    validate: (value, allValues: any) => value !== allValues[props.targetName!] ? '请确认输入密码一致' : undefined,
                    type: 'password'
                },
                formItem: {label: '确认密码'},
                input: {placeholder: '确认密码', autoComplete: 'off'}
            }
    }
});
