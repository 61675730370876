import React from 'react'
import {FormAction} from '@tencent/tea-component/lib/form'
import {BackButton, FinalFormSubmitButton} from "../common/button";
import {withPageContext2} from "../common/context";
import {Route} from "../common/link";

export const LoginFooter = withPageContext2(({context: {thirdConfig}}) => <FormAction>
    <FinalFormSubmitButton>登录</FinalFormSubmitButton>
    <Route render={(props) => {
        return props.location.state ? <BackButton/> : null
    }}/>
    {(() => {
        let list = [];
        let url = window.location.origin + '/login/oauth2/callback' + window.location.search;
        for (let registrationId in thirdConfig) {
            list.push(<a
                key={registrationId}
                href={`/v1/account/oauth2/authorization?registrationId=${encodeURIComponent(registrationId)}&url=${encodeURIComponent(url)}`}>
                <img src={thirdConfig[registrationId].logo} style={{
                    height: 24,
                    width: 24,
                    marginRight: 5,
                    verticalAlign: 'bottom'
                }} alt={registrationId}/></a>)
        }
        if (list.length > 0) {
            return <div className="other-login-mod"><span>其他登录方式</span>{list}</div>
        } else {
            return ""
        }
    })()}
</FormAction>);
