import country from '../../country.json'
import {InputProps} from '@tencent/tea-component/lib/input'
import {AutoCompleteProps} from '@tencent/tea-component/lib/autocomplete'
import {AbstractFinalField, FinalFieldProps, withFieldConfig, withFormItemConfig} from "./form"
import {InputAutoCompleteFormItem, MobileInputAutoCompleteFormItem} from "./inputFormItem";
import {PageContextType, withPageContext2} from "./context";
import {isMobile, isTablet} from 'react-device-detect'
import * as React from "react";
import {Justify} from "@tencent/tea-component/lib/justify";

export const CountryCodeFormItem = (isMobile && !isTablet) ?
    withFormItemConfig<AutoCompleteProps & InputProps>(MobileInputAutoCompleteFormItem, props => ({
        input: {
            options: country.map(it => ({
                value: it.Code.toString(),
                text: <Justify left={it.Name} right={`+${it.Code}`}/>
            })),
            placeholder: '国家码',
        }
    }))
    : withFormItemConfig<AutoCompleteProps & InputProps>(InputAutoCompleteFormItem, props => ({
        formItem: {label: '国家码', align: 'top'},
        input: {
            options: country.map(it => ({
                value: it.Code.toString(),
                text: `+${it.Code} ${it.Name}`
            })).filter(it => it.text.includes(props.input.value)),
            keyword: props.input.value,
            placeholder: '国家码',
            size: 's',
            autoComplete: 'tel-country-code',
        }
    }));

export const validateCountryCode = (nation: string) => /^\d+$/.test(nation) ? undefined : '请输入有效的国家码';

export const CountryCodeField = withPageContext2(withFieldConfig<AutoCompleteProps & InputProps, HTMLElement, string, FinalFieldProps<AutoCompleteProps & InputProps> & { context: PageContextType }>(AbstractFinalField(CountryCodeFormItem),
    ({context: {disable_country}}) => ({
        field: {validate: validateCountryCode, initialValue: '86'},
        formItem: {
            style: {display: disable_country ? 'none' : undefined},
            className: disable_country ? "" : "country-code",
        }
    })));
